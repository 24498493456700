export function HotelLogo({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 73"
      xmlSpace="preserve"
    >
      <g fill={fillColor}>
        <path
          d="M56.93,17.46c0-2.56-1.75-4.61-4.93-5.6V6.04h5.9V20.3h-1.84C56.7,19.39,56.93,18.35,56.93,17.46 M48.8,0
        C38.11,0,30.23,6.79,30.23,14.05c0,3.75,2.11,6.91,4.77,9.1l0.2-0.14c-2.11-1.74-4.02-4.48-4.02-8.22
        C31.75,6.85,41.55,2.37,50.25,2.37c6.16,0,17.23,1.42,17.23,11.72c0,5.37-5.74,12.36-17.8,12.36c-6.18,0-10.97-3.31-10.97-8.06
        c0-4.12,3.22-7.04,8.03-7.04c1.32,0,2.57,0.31,3.51,0.57c-2-0.36-5.11,0.15-6.72,1.42v6.96h5.9v-7.91c1.11,0.2,1.98,0.62,2.57,0.99
        v4.72v2.2h1.54c-1.21,1.36-3.46,2.18-5.41,1.88v0.29c5.61,0.44,7.98-2.22,7.98-5.21c0-3.52-3.3-5.59-6.68-6.01v-5.2h-5.9v5.26
        c-3.82,0.77-7.56,3.04-7.56,6.8c0,5.79,8.09,9.37,15.13,9.37c10.7,0,18.93-5.17,18.93-12.43C70.02,6.27,58.14,0,48.8,0"
        />
        <path
          d="M67.39,26.03c0-0.73,0.59-1.32,1.32-1.32c0.73,0,1.32,0.59,1.32,1.32c0,0.73-0.59,1.32-1.32,1.32
        C67.97,27.35,67.39,26.76,67.39,26.03 M69.79,26.03c0-0.6-0.48-1.09-1.08-1.09c-0.6,0-1.09,0.49-1.09,1.09
        c0,0.6,0.49,1.09,1.09,1.09C69.31,27.12,69.79,26.63,69.79,26.03 M68.43,26.11v0.67h-0.24v-1.55h0.5c0.29,0,0.58,0.08,0.58,0.43
        c0,0.18-0.11,0.32-0.32,0.36v0.01c0.21,0.04,0.24,0.14,0.26,0.3c0.02,0.15,0.04,0.31,0.09,0.45H69c-0.02-0.09-0.04-0.19-0.05-0.28
        c-0.01-0.13-0.01-0.26-0.09-0.33c-0.06-0.06-0.14-0.06-0.22-0.06H68.43L68.43,26.11z M68.68,25.87c0.23-0.01,0.28-0.1,0.28-0.22
        c0-0.12-0.05-0.18-0.25-0.18h-0.29v0.4H68.68L68.68,25.87z"
        />
        <polygon
          points="23.38,35.52 23.38,55.79 24.95,56 24.95,57.19 17.16,57.19 17.16,56 18.84,55.79 18.84,46.57 
        8.88,46.57 8.88,55.79 10.42,56 10.42,57.19 2.77,57.19 2.77,56 4.3,55.79 4.3,35.52 2.77,35.31 2.77,34.16 10.42,34.16 
        10.42,35.31 8.88,35.52 8.88,44.19 18.84,44.19 18.84,35.52 17.16,35.31 17.16,34.16 24.95,34.16 24.95,35.31 23.38,35.52 	"
        />
        <path
          d="M33.71,55.93l1.47,0.21v1.05h-7.16v-1.05l1.47-0.21V42.06l-1.47-0.21V40.8h5.7V55.93L33.71,55.93z
        M34.24,35.62c0,1.54-1.05,2.69-2.48,2.69c-1.47,0-2.48-1.05-2.48-2.59c0-1.47,1.01-2.62,2.48-2.62
        C33.19,33.11,34.24,34.26,34.24,35.62L34.24,35.62z"
        />
        <polygon
          points="38.29,56.14 39.72,55.93 39.72,34.72 38.29,34.51 38.29,33.46 43.99,33.46 43.99,55.93 45.45,56.14 
        45.45,57.19 38.29,57.19 38.29,56.14 	"
        />
        <path
          d="M54.16,40.8H58v1.54h-3.84v9.75c0,2.52,0.52,3.32,2.8,3.32c0.84,0,1.57,0,2.27-0.18v1.12
        c-2.06,0.73-3.81,1.05-5.28,1.05c-3.28,0-4.05-1.5-4.05-4.37V42.34h-3V40.8H50v-3.43l4.16-1.26V40.8L54.16,40.8z"
        />
        <path
          d="M77.17,49.15c0,5.1-3.42,8.53-8.8,8.53c-5.21,0-8.53-2.24-8.53-8.53c0-5.28,3.42-8.81,8.8-8.81
        C73.96,40.34,77.17,43.53,77.17,49.15L77.17,49.15z M72.49,49.05c0-5.17-1.54-7.45-4.02-7.45c-2.41,0-3.95,2.17-3.95,7.45
        c0,5.21,1.54,7.37,3.95,7.37C71.06,56.42,72.49,54.36,72.49,49.05L72.49,49.05z"
        />
        <path
          d="M97.04,56.14v1.05h-7.16v-1.05l1.43-0.21V46.6c0-2.59-0.94-3.74-2.9-3.74c-0.91,0-2.34,0.63-3.39,1.36v11.71
        l1.47,0.21v1.05h-7.2v-1.05l1.47-0.21V42.16l-1.47-0.21V40.8h5.73v2.27c1.78-1.43,3.49-2.59,5.9-2.59c3.21,0,4.65,1.89,4.65,5.59
        v9.85L97.04,56.14L97.04,56.14z"
        />
        <path
          d="M5.58,71.75l-0.31-4.29l-1.48,4.3H2.8l-1.49-4.3l-0.3,4.29H0l0.45-5.87h1.43l1.49,4.43l1.48-4.43h1.4
        l0.45,5.87H5.58z"
        />
        <path
          d="M10.46,71.86c-1.67,0-2.75-1.23-2.75-3.04c0-1.82,1.09-3.05,2.77-3.05c1.68,0,2.75,1.23,2.75,3.04
        C13.23,70.62,12.15,71.86,10.46,71.86z M10.47,66.71c-1,0-1.61,0.83-1.61,2.11c0,1.28,0.6,2.11,1.61,2.11
        c1.01,0,1.62-0.83,1.62-2.11C12.09,67.53,11.48,66.71,10.47,66.71z"
        />
        <path d="M14.41,71.75v-5.87h1.13v4.87h1.96l0.15,1H14.41z" />
        <path d="M18.68,71.75v-5.87h1.13v5.87H18.68z" />
        <path d="M25.07,71.75l-2.76-4.04v4.04h-1.05v-5.87h1.06l2.64,3.85v-3.85h1.05v5.87H25.07z" />
        <path
          d="M29.94,71.86c-1.67,0-2.75-1.23-2.75-3.04c0-1.82,1.09-3.05,2.77-3.05c1.68,0,2.75,1.23,2.75,3.04
        C32.7,70.62,31.62,71.86,29.94,71.86z M29.94,66.71c-1,0-1.61,0.83-1.61,2.11c0,1.28,0.6,2.11,1.61,2.11
        c1.01,0,1.62-0.83,1.62-2.11C31.56,67.53,30.95,66.71,29.94,66.71z"
        />
        <path
          d="M37.47,71.82c-0.71,0-1.33-0.16-1.85-0.41l0.17-1.03c0.49,0.29,1.11,0.52,1.69,0.52
        c0.52,0,0.88-0.25,0.88-0.71c0-0.43-0.2-0.61-1.06-0.98c-1.14-0.47-1.6-0.84-1.6-1.75c0-1.04,0.8-1.64,1.9-1.64
        c0.66,0,1.12,0.14,1.55,0.36l-0.17,1.02c-0.43-0.28-0.93-0.46-1.39-0.46c-0.56,0-0.81,0.27-0.81,0.63c0,0.37,0.19,0.55,1,0.89
        c1.21,0.49,1.65,0.91,1.65,1.82C39.45,71.22,38.6,71.82,37.47,71.82z"
        />
        <path d="M42.98,66.86v4.89h-1.13v-4.89H40.1v-0.98h4.56l0.16,0.98H42.98z" />
        <path
          d="M48.08,71.85c-1.42,0-2.35-0.84-2.35-2.37v-3.6h1.12v3.54c0,0.89,0.41,1.45,1.23,1.45
        c0.83,0,1.25-0.53,1.25-1.43v-3.56h1.13v3.57C50.47,71.01,49.5,71.85,48.08,71.85z"
        />
        <path
          d="M54.37,71.85c-1.71,0-2.78-1.21-2.78-3.03c0-1.82,1.11-3.05,2.77-3.05c0.68,0,1.21,0.16,1.64,0.43l-0.16,1.06
        c-0.38-0.29-0.86-0.52-1.43-0.52c-1.04,0-1.68,0.79-1.68,2.07c0,1.29,0.68,2.06,1.72,2.06c0.54,0,1.04-0.2,1.49-0.49l0.15,0.97
        C55.63,71.65,55.03,71.85,54.37,71.85z"
        />
        <path d="M60.8,71.81l-2.37-2.92v2.86H57.3v-5.87h1.13v2.58l2.16-2.58h1.29l-2.33,2.71l2.44,3L60.8,71.81z" />
        <path d="M65.27,69.51v2.25h-1.13v-2.24l-2.05-3.51l1.17-0.19l1.49,2.65l1.44-2.59h1.21L65.27,69.51z" />
        <path d="M67.62,72.95l-0.59-0.14l0.39-2.3h1.17L67.62,72.95z" />
        <path d="M74.49,71.78h-1.11l-2.16-5.75l1.19-0.2l1.57,4.5l1.54-4.44h1.17L74.49,71.78z" />
        <path d="M77.66,71.75v-5.87h3.41v0.95h-2.3v1.38h1.77l0.15,0.97h-1.92v1.62h2.38v0.95H77.66z" />
        <path d="M86.05,71.75l-2.77-4.04v4.04h-1.05v-5.87h1.06l2.64,3.85v-3.85h1.05v5.87H86.05z" />
        <path d="M88.44,71.75v-5.87h1.13v5.87H88.44z" />
        <path
          d="M93.57,71.85c-1.71,0-2.78-1.21-2.78-3.03c0-1.82,1.11-3.05,2.77-3.05c0.68,0,1.21,0.16,1.64,0.43l-0.16,1.06
        c-0.38-0.29-0.86-0.52-1.43-0.52c-1.04,0-1.68,0.79-1.68,2.07c0,1.29,0.68,2.06,1.72,2.06c0.54,0,1.04-0.2,1.49-0.49l0.15,0.97
        C94.83,71.65,94.23,71.85,93.57,71.85z"
        />
        <path d="M96.5,71.75v-5.87h3.41v0.95h-2.3v1.38h1.77l0.15,0.97h-1.92v1.62H100v0.95H96.5z" />
      </g>
    </svg>
  );
}
